<template>
    <v-card flat class="px-1 mt-1">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="8" class="pa-0">
          <h3 class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${projectId}`">{{ projectFullName }}</router-link></h3>
        </v-col>
        <v-col cols="4" class="pa-0 text-end pr-5 py-1">
          <v-btn v-if="checklistEnable" @click="goChecklist()" class="primary">{{ $t('message.checklist.title') }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <scroll-list v-if="renderScrollList" :key="reInit" id="WPSScrollList" :payload="scrollListPayload"></scroll-list>
    <!-- Scrollable modal dialog for pdf print view-->
    <v-dialog v-model="showPdfPreviewDialog" scrollable fullscreen>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="showPdfPreviewDialog = false; activePdfLink=''">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>PDF Preview</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;" class="pa-0">
          <pdf-viewer v-if="showPdfPreviewDialog" :fileUrl="activePdfLink"></pdf-viewer>
          <!-- <v-progress-linear class="mt-1" indeterminate color="primary" v-if="iframeSrcLoading"></v-progress-linear>
              <v-responsive :aspect-ratio="16/9" :style="`visibility: ${iframeSrcLoading ? 'hidden' : ''}`">
                <iframe onload="this.width=screen.width;this.height=screen.height;" @load="iframeSrcLoading = false" class="files-html" width="100%" :src="activePdfLink"></iframe>
              </v-responsive> -->
        </v-card-text>
      </v-card>
    </v-dialog>
   </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      listOfStatus: [this.$t('message.deviation.registered'), this.$t('message.deviation.process'), this.$t('message.deviation.closed')],
      scrollListPayload: {
        width: 60,
        module: '',
        moduleTitle: 'message.layout.wps',
        addBtnTo: '',
        pagination: {},
        totalCount: 0,
        perPage: 6,
        loading: false,
        editHandler: this.showPdfInIFrame,
        actionSize: '1', // for delete action
        list: [],
        itemHeaders: [
          'message.projectFeature.wpsNumber',
          'message.deviation.description',
          'Status'
        ],
        items: [{
          value: 'wps_number',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'description',
          class: 'pa-0 pa-1 text-justify',
          cols: '5',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: '',
          cols: '3',
          class: 'pa-0',
          rowClass: 'mt-1',
          subItems: [{
            value: 'is_completed',
            class: 'pa-0',
            cols: '12',
            spanClass: 'body'
          }]
        }],
        storeIndex: 'PROJECT_WPS_INDEX',
        isLivePagination: true,
        hasDelete: true,
        hasNoNext: true,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      MODULE: 'projectwps',
      renderScrollList: false,
      listMaxCallLoaded: false,
      loading: false,
      reInit: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.PROJECT_WPS_INDEX) ? this.$store.state.common.PROJECT_WPS_INDEX : { itemsPerPage: 6, page: 1 },
      /* project wps */
      projectId: parseInt(this.$route.params.project_id),
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      showPdfPreviewDialog: false,
      iframeSrcLoading: false,
      activePdfLink: '',
      projectFullName: '',
      checklistEnable: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue'),
    'pdf-viewer': () => import('@/components/PdfViewer/PdfViewer.vue')
  },
  created () {
    /* this.payload.editHandler = (item) => {
      // this.$router.push(`/projects/${this.projectId}/wps/action/${item.id}`)
    } */
    this.scrollListPayload.addBtnTo = `/projects/${this.projectId}/wps/action`
  },
  mounted () {
    this.scrollListPayload.module = this.MODULE
    this.getListHandler()
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `project_wps/${data.id}?user_id=${parseInt(this.hostUserId)}&project_id=${this.projectId}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.listMaxCallLoaded = false
        this.scrollListPayload.page = -1
        this.scrollListPayload.list = []
        this.getListHandler()
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getListHandler (search) {
      // const pagination = paginationObj || this.pagination
      // this.payload.pagination.page = pagination.page
      // this.payload.loading = true
      // this.payload.module = this.MODULE
      // const search = pagination.search ? pagination.search : ''
      // const pageVal = pagination.page // search ? 1 : pagination.page
      // const model = {
      //   start: ((pageVal - 1) * this.pagination.itemsPerPage),
      //   length: this.pagination.itemsPerPage,
      //   search: search
      // }
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++

      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `https://${this.hostRef}/api/project_wps?only_items=1&start=${model.start}&length=${model.length}`
      if (search) url += `&search=${search}`
      url += `&project_id=${this.projectId}`
      hostAppApi.get(url)
        .then(response => {
          this.checklistEnable = response.data.checklist_enable
          let { data, recordsFiltered, projectFullName } = response.data
          this.scrollListPayload.total = recordsFiltered
          const transformData = {
            date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          }
          data = this.$formatter.formatListModel(data, transformData)
          data = data.map(value => { return { ...value, status: this.listOfStatus[value.status - 1] } })
          this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
          if (data.length) this.scrollListPayload.totalCount = recordsFiltered
          if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
          this.projectFullName = projectFullName
          // const { data, recordsFiltered, projectFullName } = response.data
          // this.payload.total = recordsFiltered
          // const totalCount = Math.ceil(recordsFiltered / pagination.itemsPerPage)
          // if (pagination.page === totalCount) this.payload.hasNoNext = true
          // else this.payload.hasNoNext = false
          // this.payload.list = data
          // if (this.reInit === 0) this.reInit++
        }).finally(() => {
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    },
    showPdfInIFrame (item) {
      this.activePdfLink = item.download_url
      this.iframeSrcLoading = true
      this.showPdfPreviewDialog = true
    },
    goChecklist () {
      this.$router.push(`/projects/${this.$route.params.project_id}/checklists?moduleName=${this.$route.name}`)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
